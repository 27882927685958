
import Vue from 'vue'
import { mapState } from 'vuex'
import MenuItem from './MenuItem.vue'

export default Vue.extend({
  name: 'AppMenu',
  components: {
    MenuItem
  },
  computed: {
    ...mapState(['menuList'])
  },
  methods: {
    handleOpen (key: string, keyPath: string): void {
      console.log(key, keyPath)
    },

    handleClose (key: string, keyPath: string): void {
      console.log(key, keyPath)
    }
  }
})
