
import Vue from 'vue'
export default Vue.extend({
  name: 'BreadCrumb',
  computed: {
    routeRecords () {
      return this.$route.matched
    }
  }
})
