
import Vue from 'vue'
import AppMenu from '@/components/AppMenu/index.vue'

export default Vue.extend({
  name: 'AppAside',
  components: {
    AppMenu
  }
})
