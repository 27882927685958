
import { userApi } from '@/services/api'
import Vue from 'vue'
import BreadCrumb from '@/components/BreadCrumb/index.vue'
export default Vue.extend({
  name: 'AppHeader',
  components: {
    BreadCrumb
  },
  data () {
    return {
      userInfo: {} // 当前登录用户信息
    }
  },
  created () {
    this.loadUserInfo()
  },
  methods: {
    async loadUserInfo () {
      const { content } = await userApi.getInfo()
      this.userInfo = content
      // console.log('loadUserInfo')
    },

    handleLogout () {
      this.$confirm('确认退出吗？', '退出提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => { // 确认执行这里
        // 清除登录状态
        this.$store.commit('setUser', null)

        // 跳转到登录页面
        this.$router.push({
          name: 'login'
        })

        this.$message({
          type: 'success',
          message: '退出成功!'
        })
      }).catch(() => { // 取消执行这里
        this.$message({
          type: 'info',
          message: '已取消退出'
        })
      })
    }
  }
})
